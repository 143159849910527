@import 'public/less/variables.less';

.commit {
  position: relative;

  &.highlighted {
    z-index: 2;

    .commit-box {
      box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.2);
      background: #4b5766;
      left: -5px;

      .arrow {
        border-left-color: #4b5766;

        &.shadow {
          display: block;
        }
      }
    }
  }

  &.hover {
    z-index: 3;
  }

  &.selected {
    .details {
      .diff-wrapper {
        margin-bottom: 5px;
        transition: width 0.1s, left 0.05s;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);

        .diff-inner {
          box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.2);
          padding: 10px;
          padding-top: 0;
        }

        .btn-group {
          margin-top: 10px;
        }
      }
    }
  }

  .commit-box {
    .arrow {
      right: -30px;
      top: 27px;
      border-left-color: @panel-bg;

      &.shadow {
        display: none;
        right: -34px;
        top: 32px;
        border-left-color: rgba(0, 0, 0, 0.2);
      }
    }
  }

  .commit-box > .panel-body {
    position: relative;
    padding: 10px;
    margin-bottom: 0;
    width: @log-width-small;
    min-height: 85px;

    .gravatar {
      display: none;
      margin-right: 10px;
    }

    .title {
      font-size: 1.3em;
      word-wrap: break-word;
      display: block;
    }

    .details {
      .body {
        white-space: pre-wrap;
        word-wrap: break-word;
        color: #8f9fa6;
      }

      .diff-wrapper {
        margin-top: 10px;
        background: #4b5766;
        border-radius: 3px;
      }
    }
  }
}

@media (min-width: @screen-md-min) {
  .commit {
    .commit-box > .panel-body {
      width: @log-width-large;

      .gravatar {
        display: block;
      }
    }
  }
}
